import React from 'react';
import ReactPlayer from 'react-player';
import { useInView } from 'react-intersection-observer';
import './App.css';


const VideoPlayer = ({ url, isVertical }) => {
  const { ref, inView } = useInView({
    triggerOnce: false, // Permet de déclencher chaque fois que l'élément entre ou sort du viewport
    threshold: 0.4, // 50% de la vidéo doit être visible pour déclencher
  });

  return (
    <div
      ref={ref}
      className={`video-player ${isVertical ? 'vertical' : ''}`}
    >
      <ReactPlayer
        url={url}
        playing={inView}
        muted
        loop
        controls
        width="100%"
        height="100%"
      />
    </div>
  );
};


const App = () => {
  return (
    <div className="App">
      <section className="home">
        <video autoPlay loop muted className="background-video">
          <source src="site_VLT_horizontal1_compress3_very_fast_sans_audio.mp4" type="video/mp4" />
        </video>
        <div className="absolute inset-0 flex flex-col items-center justify-between text-white text-center py-8">
          <img src="logo_VLT.png" alt="Logo" className="w-1/2 md:w-1/3 lg:w-1/5" />
          <div>
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-1">Studio de Production de</h1>
            <p className="text-lg md:text-xl lg:text-2xl mt-0 font-bold">clips musicaux, aftermovies festifs & captation de concerts</p>
            <p className="text-lg mt-10">basé sur Grenoble</p>
          </div>
        </div>
      </section>

      <section className="portfolio">
        <h1>Portfolio</h1>
        <div className="video-gallery"> 
        <VideoPlayer url="https://vimeo.com/925426292?share=copy" isVertical={true} />
        <VideoPlayer url="https://vimeo.com/925439080?share=copy" isVertical={false} />
        <VideoPlayer url="https://www.youtube.com/watch?v=Prld5xqIlWE&ab_channel=HugoLange" isVertical={false} />
        <VideoPlayer url="https://vimeo.com/957014752" isVertical={true} />
        <VideoPlayer url="https://www.youtube.com/watch?v=Zq9c8qZedSg&ab_channel=TRIALaListe" isVertical={false} />
        </div>

      </section>

      <section className="contact bg-red-950 text-white flex flex-col p-12 min-h-[50vh]" >
        <h1  className='text-4xl font-bold'>Contact</h1>
        <div className="flex-grow flex flex-col items-center justify-center p-5" >
          <p>voici.la.tempete.films@gmail.com</p>
          <p>06 44 39 55 98</p>
        </div>

      </section>
      
      <section className="lafin bg-black text-white flex flex-col items-center justify-center w-full h-full">
        <p className="pb-0.5">une filiale de</p>
        <a href="https://www.voici-le-soleil.com/" target="_blank" rel="noopener noreferrer"> 
          <img src="logo_VOICI_studio_BLANC.png" alt="Logo" className='w-32 object-center p-1'/>
        </a>
      </section>

    </div>
  );
}

export default App;